import { useEffect } from "react";

const FaviconUploader = ({ faviconUrl }) => {
  useEffect(() => {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    const fileType = faviconUrl.endsWith(".ico") ? "image/x-icon" : "image/png";

    link.type = fileType;
    link.rel = "icon";
    link.href = faviconUrl;
    document.head.appendChild(link);
  }, [faviconUrl]);

  return null; // This component doesn't render anything
};

export default FaviconUploader;
