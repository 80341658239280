import { SessionProvider, signIn, useSession } from "next-auth/react";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import "react-toggle/style.css";
import useInstance from "../state/useInstance";
import "../styles/easyduct.scss";
import FaviconUploader from "./admin/super-settings/FaviconUpdater";
import LoadingPage from "./auth/loading";

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  const { instance } = useInstance();

  return (
    <>
      <SessionProvider session={session} refetchInterval={5 * 60}>
        <FaviconUploader faviconUrl={instance?.favicon || "/favicon.ico"} />
        {Component.publicPage ? (
          <PublicPages>
            <Component {...pageProps} />
          </PublicPages>
        ) : (
          <ProtectedPages>
            <Component {...pageProps} />
          </ProtectedPages>
        )}
      </SessionProvider>
    </>
  );
}

//https://github.com/nextauthjs/next-auth/issues/1210
function ProtectedPages({ children }) {
  const { status } = useSession();
  const isLoggedIn = status === "authenticated";
  const loading = status === "loading";

  useEffect(() => {
    if (loading) return;
    if (!isLoggedIn) signIn();
  }, [isLoggedIn, loading]);

  if (isLoggedIn) {
    return children;
  }

  return <LoadingPage />;
}

function PublicPages({ children }) {
  const { instance } = useInstance();
  const router = useRouter();

  useEffect(() => {
    if (!instance) return;
    if (instance?.alias !== "easyduct") {
      router.push("/auth/signin");
    }
  }, [instance]);

  return children;
}

export default MyApp;
