import useSWRImmutable from "swr/immutable";
import { fetcher } from "../lib/fetcher";

const baseUrl =
  typeof window !== "undefined" && window.location.origin
    ? window.location.origin
    : "";

const useInstance = () => {
  const {
    data,
    error,
    mutate: fetchInstance,
    isValidating,
  } = useSWRImmutable(`/api/get-instance?baseUrl=${baseUrl}`, fetcher);

  const {
    data: allInstance,
    error: fetchInstancesError,
    mutate,
  } = useSWRImmutable(`/api/get-all-instances`, fetcher);

  const isLoading = !error && !data;

  return {
    allInstance: allInstance,
    fetchInstancesError: fetchInstancesError,
    fetchAllInstance: mutate,
    fetchInstance: fetchInstance,
    instance: data,
    isFetchingInstance: isValidating,
    isError: error,
  };
};

export default useInstance;
