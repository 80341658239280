import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SectionBg from "../../public/images/bg-center.png";

export default function LoadingPage() {
  return (
    <div
      className="flex items-center justify-center h-screen flex-col"
      style={{
        backgroundColor: "#fff",
        backgroundImage: `url("${SectionBg.src}")`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "1219",
      }}
    >
      <FontAwesomeIcon icon={faSpinner} className="animate-spin" size="2x" />
    </div>
  );
}

LoadingPage.publicPage = true;
